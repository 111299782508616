import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import styles from './ModalComponent.module.css'; // Supondo que você tenha um arquivo CSS

// Para acessibilidade, é necessário associar o modal ao root da aplicação
Modal.setAppElement('#root');

const ModalComponent = ({ isOpen, closeModal, email, phone, billType }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [linhaDigitavel, setLinhaDigitavel] = useState('');
  const [fetchTriggered, setFetchTriggered] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null); // Limpar erro anterior
    try {
      const response = await fetch('https://carinapaga.com.br/api/consultation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, phone, billType, linhaDigitavel }),
      });
      const result = await response.json();
      if (response.ok) {
        setData(result);
      } else {
        setError(result.message || 'Erro desconhecido');
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  }, [email, phone, billType, linhaDigitavel]);

  useEffect(() => {
    if (isOpen && fetchTriggered) {
      fetchData();
    }
  }, [isOpen, fetchTriggered, fetchData]);

  const handleLinhaDigitavelChange = (event) => {
    setLinhaDigitavel(event.target.value);
  };

  const handleFetchData = () => {
    if (linhaDigitavel.trim()) {
      setFetchTriggered(true);
    } else {
      setError('Por favor, insira a linha digitável.');
    }
  };

  const handleRedirect = () => {
    window.location.href = '/login'; // Substitua com o caminho correto da sua página de login
  };

  const handleRegister = () => {
    window.location.href = '/register'; // Substitua com o caminho correto da sua página de login
  };

  // Função para mapear os tipos de conta
  const mapBillType = (type) => {
    const map = {
      energia: 'Energia',
      agua: 'Água',
      'Débitos Veiculares': 'Débitos Veiculares',
      'Outros Boletos': 'Outros Boletos',
    };
    return map[type] || 'Desconhecido';
  };

  // Função para formatar a data no formato DD/MM/YYYY
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  // Função para formatar os dados em HTML
  const formatData = (data) => {
    if (!data || !data.success) return '';

    const {billType, simulationResult } = data.data || {};
    const { bankSlipInfo } = simulationResult || {};
    const { identificationField, value, dueDate, isOverdue} = bankSlipInfo || {};

    return `
    <p><strong>Tipo de Conta:</strong> ${mapBillType(billType)}</p>
    <p><strong>Valor:</strong> R$ ${value.toFixed(2)}</p>
    <p><strong>Data de Vencimento:</strong> ${formatDate(dueDate)}</p>
    <p><strong>Identificação do Boleto:</strong> ${identificationField}</p>
    <p><strong>Vencido:</strong> ${isOverdue ? 'Sim' : 'Não'}</p>
  `;
  
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Resultado da Consulta"
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      <button onClick={closeModal} className={styles.closeButton}>
        &times;
      </button>
      <h2>Resultado da Consulta</h2>
      {!fetchTriggered ? (
        <div>
          <input
            type="text"
            value={linhaDigitavel}
            onChange={handleLinhaDigitavelChange}
            placeholder="Insira a linha digitável do boleto"
            className={styles.input}
          />
          <button onClick={handleFetchData} className={styles.button}>
            Realizar a Consulta Gratuita
          </button>
        </div>
      ) : loading ? (
        <p>Carregando os resultados...</p>
      ) : error ? (
        <div>
          <p>{error}</p>
          <div className={styles.redirectButtonContainer}>
            <button onClick={handleRedirect} className={styles.redirectButton}>
             Ir para a página de login
            </button>
          </div>
        </div>
      ) : data ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: formatData(data) }} />
          <div className={styles.redirectButtonContainer}>
            <button onClick={handleRegister} className={styles.redirectButton}>
              Pague com a CarinaPaga
            </button>
          </div>
        </div>
      ) : (
        <p>Não foram encontrados dados.</p>
      )}
    </Modal>
  );
};

export default ModalComponent;
