import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModalComponent from './ModalSimulatePayment2';
import styles from './ListTransations.module.css';
import Sidebar from './Sidebar';

const FormFaturas23 = () => {
  const [userData, setUserData] = useState(null);
  const [externalId, setExternalId] = useState('');
  const [EmailClient, setEmailClient] = useState('');
  const [payments, setPayments] = useState([]); // Estado para armazenar os pagamentos
  const history = useHistory();



  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');

    if (!storedCpfCnpj) {
      // Se não há CPF/CNPJ no localStorage, redireciona para a página de login
      history.push('/login');
    } else {
      // Se há, redireciona para a página de simulação de pagamento
      history.push('/list-transations');
      fetchUserData(storedCpfCnpj);
    }
  }, [history]);

  const fetchUserData = async (cpfcnpj) => {
    try {
      const response = await fetch(`https://carinapaga.com.br/api/user/${cpfcnpj}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar os dados do usuário');
      }

      const data = await response.json();
      console.log(data);
      const NomeCompleto = `${data.firstName} ${data.lastName}`;
      const Email = data.email;
      const externalId = data.externalId;
      setEmailClient(Email);
      setExternalId(externalId);
      setUserData(NomeCompleto);

      // Chama fetchPayments aqui, garantindo que externalId esteja definido
      fetchPayments(externalId);
    } catch (error) {
      console.error('Erro ao buscar os dados do usuário:', error);
    }
  };

  const fetchPayments = async (externalId) => {
    console.log(externalId);
    try {
      const response = await fetch(`https://carinapaga.com.br/api/pagamentos/customer/${externalId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar os dados dos pagamentos');
      }

      const data = await response.json();
      console.log(data);
      setPayments(data); // Armazena os dados dos pagamentos no estado

    } catch (error) {
      console.error('Erro ao buscar os dados dos pagamentos:', error);
    }
  };

  const formatValue = (value) => {
    return parseFloat(value).toFixed(2).replace('.', ','); // Formata para duas casas decimais e substitui ponto por vírgula
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Garante que o dia tenha 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa do zero, por isso somamos 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`; // Retorna a data no formato dd/mm/aaaa
  };

  const formatStatus = (status) => {
    switch (status) {
      case 'CONFIRMED':
        return 'CONFIRMADO'; // Transforma CONFIRMED em CONFIRMADO
      case 'RECEIVED':
        return 'RECEBIDO'; // Transforma RECEIVED em RECEBIDO
      case 'OVERDUE':
        return 'ATRASADO'; // Substituição do status
      default:
        return status; // Retorna o status original se não houver alteração
    }
  };

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h2>Últimas Transações Realizadas</h2>
                <p>Acompanhe suas últimas transações realizadas com a CarinaPaga e mantenha o controle total sobre seus pagamentos, seja via PIX, boleto ou cartão de crédito. Nossa plataforma garante que suas faturas e despesas sejam processadas de forma rápida e segura, proporcionando uma gestão de pagamentos eficiente e sem complicações. Aproveite a praticidade e a confiabilidade da CarinaPaga para gerenciar suas finanças com mais tranquilidade.</p>
                <br />

                {/* Tabela de transações */}
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome Completo</th>
                      <th>Email</th>
                      <th>Valor (R$)</th>
                      <th>Data Gerada</th>
                      <th>Tipo de Pagamento</th>
                      <th>Parcelas</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment) => (
                      <tr key={payment.id}>
                        <td>{payment.id}</td>
                        <td>{userData}</td> {/* Nome Completo do cliente */}
                        <td>{EmailClient}</td> {/* Email do cliente */}
                        <td>{formatValue(payment.value)}</td> {/* Valor formatado */}
                        <td>{formatDate(payment.dueDate)}</td> {/* Data formatada */}
                        <td>{payment.billingType || ''}</td>
                        <td>{payment.installment || ''} </td>
                        <td>{formatStatus(payment.status)}</td> {/* Status formatado */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FormFaturas23;
