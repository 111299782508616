import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import styles from './ModalSimulateComponent.module.css'; // Supondo que você tenha um arquivo CSS
import { toast, ToastContainer } from 'react-toastify';
// import BandeiraCartoes from '../../src/assets/bandeiras2.png';

// Para acessibilidade, é necessário associar o modal ao root da aplicação
Modal.setAppElement('#root');

const ModalComponent = ({ isOpen, closeModal, billType, billType2}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [fetchTriggered, setFetchTriggered] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [installments, setInstallments] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [setSavedBillType] = useState(billType2); // Armazenar o billType

  // Estado para o segundo modal
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isPaymentReceivedModalOpen, setPaymentReceivedModalOpen] = useState(false); // Novo estado para modal de pagamento recebido
  const [isPaymentReceivedModalOpen2, setPaymentReceivedModalOpen2] = useState(false); // Novo estado para modal de pagamento recebido

  // Estados para cartões de crédito // Débito
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cpf, setCpf] = useState('');
  const [AddressNumber, setAddressNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [cep, setCep] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [setPaymentId] = useState('');
  const [pixCode, setPixCode] = useState('');
  const [isPaymentReceived, setIsPaymentReceived] = useState(false); // Estado para controle de recebimento

  // Formatando o cartão de crédito // Débito
  const formatPaymentMethod = () => {
    switch (paymentMethod) {
      case 'credito':
        return 'Crédito';
      case 'pix':
        return 'PIX';
      default:
        return 'Método de Pagamento Indefinido';
    }
  };

  const handleCepChange = (e) => {
    const value = e.target.value;
    setCep(value);

    // Regex para validar o formato do CEP
    const cepRegex = /^[0-9]{5}-?[0-9]{3}$/;

    if (!cepRegex.test(value)) {
      setError('CEP inválido. O formato deve ser XXXXX-XXX ou 8 dígitos.');
    } else {
      setError('');
    }
  };

  const handleCreditPaymentSubmit = async () => {
    console.log("Processando pagamento com cartão de débito/crédito...");
  
    if (paymentMethod === 'credito') {
      const cpfcnpj = localStorage.getItem('cpfcnpj');
      if (!cpfcnpj) {
        alert('CPF/CNPJ não encontrado. Por favor, faça login novamente.');
        return;
      }
  
      try {
        // Fazendo a requisição para obter os dados do cliente
        const customerResponse = await fetch(`https://carinapaga.com.br/api/app/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cpfcnpj }),
        });
  
        // Verificação se a resposta está ok
        if (!customerResponse.ok) {
          const errorData = await customerResponse.json();
          console.error('Erro ao obter dados do cliente:', errorData);
          alert('Erro ao obter os dados do cliente. Tente novamente.');
          return;
        }
  
        // Tentando parsear a resposta para JSON
        let customerData;
        try {
          customerData = await customerResponse.json();
        } catch (jsonError) {
          console.error('Erro ao converter resposta para JSON:', jsonError);
          alert('Erro ao processar os dados do cliente. Tente novamente.');
          return;
        }
  
        // Verificando se os dados do cliente foram encontrados
        if (!customerData || !customerData.externalId) {
          setError('Cliente não encontrado.');
          // console.log('Cliente não encontrado:', customerData);
          return;
        }

        const EmailClient = customerData.email;
        const PhoneClient = customerData.phone;
        // console.log("Parcelas:" + installments)
  
        // Extrair dados da validade do cartão (separar mês e ano)
        const [cardExpiryMonth, cardExpiryYear] = expiryDate.split('/');
  
        const paymentData = {
          installmentCount: installments,
          cpfcnpj: cpfcnpj,
          email: EmailClient,
          phone: PhoneClient,
          addressNumber: AddressNumber,
          postalCode: cep,
          value: totalAmount,
          description: 'Pagamento via Credito',
          billingType: 'CREDIT_CARD', // Mantém como pagamento por cartão
          cardHolderName: cardHolder, // Nome no cartão
          cardNumber: cardNumber, // Número do cartão
          cardExpiryMonth: cardExpiryMonth.trim(), // Mês de validade
          cardExpiryYear: cardExpiryYear.trim(), // Ano de validade
          cardCvv: cvv // Código de segurança (CVV)
        };
  
        // Log dos dados que serão enviados para depuração
        console.log('Enviando dados do pagamento:', paymentData);
  
        // Fazendo a requisição para processar o pagamento
        const paymentResponse = await fetch('https://carinapaga.com.br/api/app/createCharge/credit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paymentData),
        });
  
        // Tentando parsear a resposta do pagamento para JSON
        const result = await paymentResponse.json();
  
        // Verificando se o pagamento foi processado com sucesso
        if (paymentResponse.ok) {
          console.log('Pagamento via Cartão de Crédito/Débito processado com sucesso:', result);
          setPaymentReceivedModalOpen2(true); // Abre o modal de pagamento recebido
          // alert('Pagamento processado com sucesso!');
          toast.success('Seu pagamento está em análise. Por favor, verifique o status na lista de transações.');
        } else {
          // console.error('Erro no processamento do pagamento:', result);
          // alert(`Erro no processamento do pagamento: ${result.error || 'Tente novamente.'}`);
          toast.error('Erro no processamento do pagamento, verifique os dados do cartão e tente novamente.');
        }
  
      } catch (error) {
        toast.error('Erro na comunicação com o servidor. Tente novamente mais tarde.');
        // console.error('Erro geral na requisição:', error);
        // alert('Erro na comunicação com o servidor. Tente novamente.');
      }
  
    } else {
      // console.log(`Pagamento por ${paymentMethod} no valor de R$ ${totalAmount.toFixed(2)}`);
    }
  };
  

  const handlePixPaymentSubmit = async () => {
    const cpfcnpj = localStorage.getItem('cpfcnpj');
    if (!cpfcnpj) {
      alert('CPF/CNPJ não encontrado. Por favor, faça login novamente.');
      return;
    }

    const paymentData = {
      cpfcnpj: cpfcnpj,
      value: totalAmount,
      description: 'Pagamento via PIX',
      billingType: 'PIX'
    };

    try {
      const response = await fetch('https://carinapaga.com.br/api/app/createCharge', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Pagamento via PIX processado com sucesso:', result);
        setQrCode(result.qrCode); // Armazenar QR Code
        setPixCode(result.pixCode); // Armazenar payload
        setPaymentId(result.paymentId)
        setSavedBillType(result.billingType)
        setIsPaymentReceived(true); // Marca que os dados foram recebidos

        // Função para verificar o status do pagamento
        const checkPaymentStatus = async () => {
          const statusResponse = await fetch(`https://carinapaga.com.br/api/payments/status/${result.paymentId}`);
          const statusResult = await statusResponse.json();
          console.log("Status Completo e Realizado: " + statusResult)
          const ResultPayment = result.paymentId;

          if (statusResponse.ok) {
            console.log('Status do pagamento:', statusResult.status);

            if (statusResult.status === 'RECEIVED') { // Adicionar aqui a logística para pagar o boleto:
              setPaymentReceivedModalOpen(true); // Abre o modal de pagamento recebido
              clearInterval(checkInterval); // Para a verificação após o pagamento ser recebido
              
              const PaymentBoleto = {
                  billType: billType, // billType previamente salvo
                  paymentId: ResultPayment // paymentId recebido
              }
          
              const PaymentConfirm = await fetch('https://carinapaga.com.br/api/payments/boleto', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(PaymentBoleto),
              });
              
              const StatusPayment = await PaymentConfirm.json(); // Aguarda a resposta e converte para JSON
              console.log(StatusPayment); // Loga o status do pagamento
          }
          
          } else {
            console.error('Erro ao verificar status do pagamento:', statusResult.error);
          }
        };

        // Intervalo para verificar o status a cada 5 segundos
        const checkInterval = setInterval(checkPaymentStatus, 5000);

      } else {
        console.error('Erro ao processar pagamento via PIX:', result.error);
        alert('Erro ao processar pagamento. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      alert('Erro na comunicação com o servidor. Tente novamente.');
    }
  };



  const handleClosePaymentReceivedModal = () => {
    setPaymentReceivedModalOpen(false);
    setPaymentReceivedModalOpen2(false);
    setPaymentModalOpen(false); // Fecha o segundo modal
    closeModal();
  };


  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null); // Limpar erro anterior
    try {
      const response = await fetch('https://carinapaga.com.br/api/app/consultation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ billType }),
      });
      const result = await response.json();
      if (response.ok) {
        setData(result);
      } else {
        setError(result.message || 'Erro desconhecido');
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  }, [billType]);

  useEffect(() => {
    if (isOpen && !fetchTriggered) {
      setFetchTriggered(true);
    }
  }, [isOpen, fetchTriggered]);

  useEffect(() => {
    if (fetchTriggered) {
      fetchData();
    }
  }, [fetchTriggered, fetchData]);

  // Função para calcular o total com base no valor, método de pagamento e número de parcelas
  const calculateTotal = (value, paymentMethod, installments) => {
    let total = 0;
    const numericValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);

    if (paymentMethod === 'boleto') {
      total = numericValue + 2.99; // Taxa do boleto
    } else if (paymentMethod === 'credito') {
      let percentage = 0;
      if (installments === 1) {
        percentage = 0.0399; // 3,99% para à vista
      } else if (installments === 2) {
        percentage = 0.0449 * 2; // 4,49% para 2 parcelas
      } else if (installments === 3) {
        percentage = 0.0449 * 3; // 4,49% para 3 parcelas
      } else if (installments === 4) {
        percentage = 0.0449 * 4; // 4,49% para 4 parcelas
      } else if (installments === 5) {
        percentage = 0.0449 * 5; // 4,49% para 5 parcelas
      } else if (installments === 6) {
        percentage = 0.0449 * 6; // 4,49% para 6 parcelas
      } else if (installments === 7) {
        percentage = 0.0499 * 7; // 4,99% para 7 parcelas
      } else if (installments === 8) {
        percentage = 0.0499 * 8; // 4,99% para 8 parcelas
      } else if (installments === 9) {
        percentage = 0.0499 * 9; // 4,99% para 9 parcelas
      } else if (installments === 10) {
        percentage = 0.0499 * 10; // 4,99% para 10 parcelas
      } else if (installments === 11) {
        percentage = 0.0499 * 11; // 4,99% para 11 parcelas
      } else if (installments === 12) {
        percentage = 0.0499 * 12; // 4,99% para 12 parcelas
      }
      total = numericValue * (1 + percentage) + 14.49; // Aplica a porcentagem + taxa fixa
    } else if (paymentMethod === 'pix') {
      total = numericValue + 0.099 + 5.00; // Taxa fixa de R$5,00 para PIX
    } else {
      console.error(`Método de pagamento não reconhecido: ${paymentMethod}`);
      return;
    }
    setTotalAmount(total);
  };

  const handlePaymentMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);

    if (data && data.data && data.data.simulationResult) {
      let valueToCalculate = data.data.simulationResult.bankSlipInfo?.value || 0;
      calculateTotal(valueToCalculate, selectedMethod, installments);
    }
  };

  const handleInstallmentsChange = (e) => {
    const selectedInstallments = Number(e.target.value);
    setInstallments(selectedInstallments);

    if (data && data.data && data.data.simulationResult) {
      let valueToCalculate = data.data.simulationResult.bankSlipInfo?.value || 0;
      calculateTotal(valueToCalculate, paymentMethod, selectedInstallments);
    }
  };

  const handleRedirect = () => {
    window.location.href = '/pagar-pix';
  };

  const handleOpenPaymentModal = () => {
    setPaymentModalOpen(true); // Abre o segundo modal
  };

  const handleClosePaymentModal = () => {
    setPaymentModalOpen(false); // Fecha o segundo modal
  };

  // Função para formatar a data no formato DD/MM/YYYY
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const formatData = (data) => {
    if (!data || !data.success) return '';

    const { simulationResult } = data.data || {};
    const { bankSlipInfo } = simulationResult || {};
    const { identificationField, value, dueDate, isOverdue } = bankSlipInfo || {};

    return `
      <p><strong>Valor:</strong> R$ ${value.toFixed(2)}</p>
      <p><strong>Data de Vencimento:</strong> ${formatDate(dueDate)}</p>
      <p><strong>Identificação do Boleto:</strong> ${identificationField}</p>
      <p><strong>Vencido:</strong> ${isOverdue ? 'Sim' : 'Não'}</p>
    `;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Resultado da Consulta"
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <h2>Resultado da Consulta</h2>
        {loading ? (
          <p>Carregando os resultados...</p>
        ) : error ? (
          <div>
            <p>{error}</p>
            <div className={styles.redirectButtonContainer}>
              <button onClick={handleRedirect} className={styles.redirectButton}>
                Retornar
              </button>
            </div>
          </div>
        ) : data ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: formatData(data) }} />

            <div className={styles.paymentMethodContainer}>
              <select onChange={handlePaymentMethodChange} className={styles.input} style={{ marginLeft: '5rem' }}>
                <option value="">Selecione o método de pagamento</option>
                {/* <option value="boleto">Boleto</option> */}
                {/* <option value="credito">Crédito</option> */}
                <option value="pix">PIX</option>
              </select>
            </div>

            {paymentMethod === 'credito' && (
              <div className={styles.installmentsContainer}>
                <label style={{ marginLeft: '9rem' }}>
                  <strong>Quantidade de Parcelas:</strong>
                  <select
                    value={installments}
                    onChange={handleInstallmentsChange}
                    className={styles.input}
                    style={{ marginLeft: '-0.1rem' }}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                      <option key={i} value={i}>
                        {i}x
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            )}

            <div className={styles.totalAmountContainer}>
              <h3 style={{ marginTop: '20px', marginLeft: '9rem' }}>Total a pagar:</h3>
              <p>R$ {totalAmount.toFixed(2)}</p>
            </div>

            <div className={styles.paymentButtonContainer}>
              <button onClick={handleOpenPaymentModal} className={styles.paymentButton}>
                Realizar Pagamento
              </button>
            </div>
          </div>
        ) : null}
      </Modal>
      {/* Segundo modal de pagamento */}
      <Modal
        isOpen={isPaymentModalOpen}
        onRequestClose={handleClosePaymentModal}
        contentLabel="Confirmação de Pagamento"
        className={styles.modalContent2}
        overlayClassName={styles.modalOverlay}
      >
        <h2 style={{ textAlign: 'center' }}>Confirmação de Pagamento</h2>
        <p style={{ textAlign: 'center' }}>
          Método de Pagamento: <strong>{formatPaymentMethod()}</strong>
        </p>

        {/* Condicional para exibir a quantidade de parcelas se o método for crédito */}
        {paymentMethod === 'credito' && (
          <p style={{ textAlign: 'center' }}>
            Parcelas: <strong>{installments}x</strong>
          </p>
        )}

        <p style={{ textAlign: 'center' }}>
          Total: <strong>R${totalAmount.toFixed(2)}</strong>
        </p>

        {/* Exibir inputs do cartão de crédito/débito se for essa a forma de pagamento */}
        {(paymentMethod === 'credito') && (
          <div className={styles.cardInputsContainer}>
            <label>
              <strong style={{ marginLeft: '9rem' }}>Número do Cartão:</strong>
              <input
                type="text"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                className={styles.input}
              />
            </label>

            <label>
              <strong style={{ marginLeft: '10.5rem' }}>Nome do Titular:</strong>
              <input
                type="text"
                value={cardHolder}
                onChange={(e) => setCardHolder(e.target.value)}
                className={styles.input}
              />
            </label>

            <label>
              <strong style={{ marginLeft: '9.5rem' }}>Data de Expiração:</strong>
              <input
                type="text"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                className={styles.input}
                placeholder="MM/AA"
              />
            </label>

            <label>
              <strong style={{ marginLeft: '13rem' }}>CVV:</strong>
              <input
                type="text"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                className={styles.input}
              />
            </label>

            <label>
              <strong style={{ marginLeft: '8.5rem' }}>Número do Endereço:</strong>
              <input
                type="text"
                value={AddressNumber}
                onChange={(e) => setAddressNumber(e.target.value)}
                className={styles.input}
              />
            </label>

            <label>
              <strong style={{ marginLeft: '8rem' }}>CEP do Titular (Endereço):</strong>
              <input
                type="text"
                value={cep}
                onChange={handleCepChange}
                className={styles.input}
              />
            </label>

            <div className={styles.fullWidthContainer}>
              <label>
                <strong style={{ marginLeft: '21.5rem' }}>CPF do Titular:</strong>
                <input
                  type="text"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  className={`${styles.fullWidthInput}`}
                />
              </label>
            </div>
          </div>
        )}

        {/* Exibir instruções de pagamento para PIX */}
        {paymentMethod === 'pix' && isPaymentReceived && (
          <div style={{ textAlign: 'center' }}>
            <h3>QR Code para Pagamento</h3>
            {qrCode && <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" style={{ width: '200px', height: '200px' }} />}
            <p>Por favor, faça o pagamento via PIX utilizando o QR Code acima.</p>
            <p><strong>PIX Copia e Cola:</strong></p>
            {/* Input desabilitado para o PIX code */}
            <input
              type="text"
              value={pixCode}
              readOnly
              className={styles.input3}
            />
            {/* Botão de copiar */}
            <button
              onClick={() => {
                navigator.clipboard.writeText(pixCode)
                  .then(() => {
                    alert('Código PIX copiado para a área de transferência!');
                  })
                  .catch((err) => {
                    console.error('Erro ao copiar o código PIX:', err);
                  });
              }}
              style={{
                padding: '10px 15px',
                backgroundColor: '#e63888',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Copiar PIX
            </button>

          </div>
        )}


        {/* Botão de confirmação para pagamento com Cartão de Crédito */}
        {paymentMethod === 'credito' && (
          <>
            <p style={{ textAlign: 'center' }}>
              Ao clicar em Confirmar Pagamento, você confirma que leu e concorda com os{' '}
              <strong style={{ color: '#e63888' }}>termos de uso e privacidade</strong>.
            </p>
            <button onClick={handleCreditPaymentSubmit} className={styles.submitButton} style={{ marginTop: '0rem' }}>
              Confirmar Pagamento com Cartão de Crédito
            </button>
          </>
        )}

        {/* Botão de confirmação para pagamento com PIX */}
        {paymentMethod === 'pix' && (
          <>
            {!isPaymentReceived && ( // Renderiza o texto apenas se o pagamento não foi recebido
              <p style={{ textAlign: 'center' }}>
                Ao clicar em Confirmar Pagamento, você confirma que leu e concorda com os{' '}
                <strong style={{ color: '#e63888' }}>termos de uso e privacidade</strong>.
              </p>
            )}
            {!isPaymentReceived && ( // Renderiza o botão apenas se o pagamento não foi recebido
              <button onClick={handlePixPaymentSubmit} className={styles.submitButton} style={{ marginTop: '0rem' }}>
                Confirmar Pagamento com PIX
              </button>
            )}
          </>
        )}

      </Modal>
      <Modal isOpen={isPaymentReceivedModalOpen} onRequestClose={handleClosePaymentReceivedModal} className={styles.modalContent2} overlayClassName={styles.modalOverlay}>
        <h2 style={{ textAlign: 'center' }}>Pagamento Confirmado</h2>
        <p style={{ textAlign: 'center' }}>Seu pagamento foi processado com sucesso através da CarinaPaga. Você agora pode relaxar, pois uma conta a menos!</p>
        {/* Adicione mais informações sobre o pagamento, se necessário */}
        <button onClick={handleClosePaymentReceivedModal}>Fechar</button>
      </Modal>

      <Modal isOpen={isPaymentReceivedModalOpen2} onRequestClose={handleClosePaymentReceivedModal} className={styles.modalContent2} overlayClassName={styles.modalOverlay}>
        <h2 style={{ textAlign: 'center' }}>Pagamento em Análise</h2>
        <p style={{ textAlign: 'center' }}>Seu pagamento foi recebido e está em análise através da CarinaPaga. Você pode acompanhar o status de aprovação na sua lista de transações.</p>
        {/* Adicione mais informações sobre o pagamento, se necessário */}
        <button onClick={handleClosePaymentReceivedModal}>Fechar</button>
      </Modal>
      <ToastContainer /> {/* Adiciona o ToastContainer aqui */}
    </>
  );
};

export default ModalComponent;