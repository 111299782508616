import React, { useState } from 'react';
import styles from './Home.module.css';
import Seguranca from '../assets/medalhaseguranca.png';
import FAQAccordion from "../components/FAQAccordion";
import Services from "../components/services";
import FormFaturas from '../components/FormFaturas';

function Home() {
    // Estado para controlar o texto ativo
    const [activeText] = useState(1); // Se for questão veícular altere aqui para 2, caso seja só pagamento de faturas altere para 1;

    return (
        <div className={styles.body}>
            <div className="row align-items-center">
                <div className="col-md-6">
                    {/* Renderiza o primeiro ou segundo texto com base no estado */}
                    {activeText === 1 ? (
                        <h1 className={styles.h1principal}>
                            Pague suas faturas de água, energia e muito mais em até <span>12x</span> no <span>cartão de crédito!</span>
                        </h1>
                    ) : (
                        <h1 className={styles.h1principal}>
                            Pague seus <span>débitos veiculares</span> com rapidez e segurança, parcelando em <span>até 12x no cartão!</span>
                        </h1>
                    )}
                    <p className={styles.h1descricao}>
                        Aproveite a conveniência e facilidade de pagamento parcelado para manter suas contas em dia sem comprometer seu orçamento.
                    </p>
                    <img src={Seguranca} alt="" className={styles.h1img} />
                </div>
                <FormFaturas />
            </div>

            <div className="spacer-10"></div>
            <div className="row align-items-center">
                <div className="m-4"></div>
                <h1 className={styles.personalizado}>
                    Pague com a <a className={styles.parcelbutton} href='/'>CarinaPaga</a> com muita facilidade
                </h1>
                <p className="font-weight-bold" style={{ color: "#000826", fontWeight: '400', fontSize: '1.0rem', lineHeight: '1.75rem' }}>
                    Aproveite a conveniência e facilidade de pagamento parcelado para manter suas contas em dia sem comprometer seu orçamento.
                </p>
                <div id="services-section">
                    <Services />
                </div>
                <h1 className={styles.personalizado}>
                    Está com alguma <a className={styles.parcelbutton2} href='/'>dúvida?</a><br />Veja nossas perguntas frequentes:
                </h1>
                <div id="duvidas-frequentes">
                    <FAQAccordion />
                </div>
                <div className="m-2"></div>
            </div>
        </div>
    );
}

export default Home;
