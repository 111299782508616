import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import styles from './Navbar.module.css';
import Container from '../layout/Container';
import Button from '@mui/material/Button';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={styles.navbar}>
            <Container>
                <div className={styles.navHeader}>
                    <Link to="/" className={styles.logoLink}>
                        <h1 className={isMenuOpen ? styles.showOnMenu : styles.showOnDesktop}>CarinaPaga</h1>
                    </Link>
                    {/* Menu Button */}
                    <button className={styles.menuButton} onClick={toggleMenu}>
                        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                    </button>
                </div>

                <ul className={`${styles.list} ${isMenuOpen ? styles.listOpen : ''}`}>
                    <li className={styles.item2}>
                        <Link to="/">Início</Link>
                    </li>
                    <li className={styles.item2}>
                        <ScrollLink
                            to="services-section" // ID da seção "Services"
                            smooth={true}
                            duration={500}
                            offset={-70} // Ajuste para navbars fixas
                            className={styles.item2}
                        >
                            Serviços
                        </ScrollLink>
                    </li>
                    <li className={styles.item2}>
                        <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer">Ajuda</a>
                    </li>

                    <li className={styles.item2}>
                        <ScrollLink
                            to="duvidas-frequentes" // ID da seção "FAQ"
                            smooth={true}
                            duration={500}
                            offset={-70}
                            className={styles.item2}
                        >
                            Dúvidas Frequentes
                        </ScrollLink>
                    </li>
                    <li className={`${styles.item2} ${styles.mobileOnly}`}>
                        <a href="https://carinabank.com.br" target="_blank" rel="noopener noreferrer">
                            CarinaBank
                        </a> {/* Link para CarinaBank */}
                    </li>
                    <li className={styles.item}>
                        <Link to="/login">
                            <Button style={{ background: "rgb(255, 255, 255)", color: "#000", border: "1px solid #e63888", borderRadius: '5px' }}>
                                Acompanhar pedidos <ArrowCircleRightIcon />
                            </Button>
                        </Link>
                    </li>
                </ul>
            </Container>
        </nav>
    );
}

export default Navbar;
