import React, { useCallback, useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import styles from './CheckoutFinish.module.css';

const CheckoutFinish = ({ code }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [paymentOption, setPaymentOption] = useState('oneCard');
    const [cardDetails, setCardDetails] = useState({
        cvc: '',
        expiry: '',
        name: '',
        number: '',
    });
    const [cardDetails2, setCardDetails2] = useState({
        cvc: '',
        expiry: '',
        name: '',
        number: '',
    });
    const [focused, setFocused] = useState('number');
     // Estado para armazenar a parcela selecionada
     const [installments, setInstallments] = useState('12'); // Inicializa com 12x como a melhor opção

     // Função para lidar com a mudança de parcelamento
     const handleInstallmentChange = (event) => {
         setInstallments(event.target.value); // Atualiza o estado com o valor selecionado
     };
 

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://carinapaga.com.br/api/app/consultation', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ billType: code }),
            });

            const result = await response.json();

            if (response.ok) {
                setData(result.data.simulationResult.bankSlipInfo);
            } else {
                const errorMessage = result.errors?.[0]?.description || result.message || 'Erro desconhecido';
                setError(errorMessage);
            }
        } catch (error) {
            setError('Erro ao conectar com o servidor.');
        } finally {
            setLoading(false);
        }
    }, [code]);

    useEffect(() => {
        if (code) {
            fetchData();
        }
    }, [code, fetchData]);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleInputChange = (e, cardNumber) => {
        const { name, value } = e.target;
        if (cardNumber === 1) {
            setCardDetails((prevState) => ({ ...prevState, [name]: value }));
        } else {
            setCardDetails2((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const handleFocusChange = (e) => {
        setFocused(e.target.name);
    };

    const renderPaymentFields = () => {
        switch (paymentOption) {
            case 'oneCard':
                return (
                    <div className={styles.cardDetails}>
                        <h3>Cartão de Crédito</h3>
                        <Cards
                            cvc={cardDetails.cvc}
                            expiry={cardDetails.expiry}
                            focused={focused}
                            name={cardDetails.name}
                            number={cardDetails.number}
                        />
                        <label>Número de Parcelas</label>
                        <div className={styles.bestOption}>
                            <span className={styles.highlightText}>Melhor opção:</span>
                            <div className={styles.installmentsOptions}>
                                {/* Card para 12x */}
                                <label className={styles.cardOption}>
                                    <input
                                        type="radio"
                                        name="installments"
                                        value="12"
                                        checked={installments === '12'}
                                        onChange={handleInstallmentChange}
                                    />
                                    <div className={styles.card}>
                                        <h4>12x</h4>
                                        <p className={styles.cardText}>de R$ 25,95 (Menor valor por parcela!)</p>
                                    </div>
                                </label>

                                {/* Card para 6x */}
                                <label className={styles.cardOption}>
                                    <input
                                        type="radio"
                                        name="installments"
                                        value="6"
                                        checked={installments === '6'}
                                        onChange={handleInstallmentChange}
                                    />
                                    <div className={styles.card}>
                                        <h4>6x</h4>
                                        <p className={styles.cardText}>de R$ 50,00</p>
                                    </div>
                                </label>

                                {/* Card para 1x */}
                                <label className={styles.cardOption}>
                                    <input
                                        type="radio"
                                        name="installments"
                                        value="1"
                                        checked={installments === '1'}
                                        onChange={handleInstallmentChange}
                                    />
                                    <div className={styles.card}>
                                        <h4>1x</h4>
                                        <p className={styles.cardText}>de R$ 300,00</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <br></br>
                        <label>Número do Cartão</label>
                        <input
                            type="text"
                            name="number"
                            placeholder="Número do Cartão"
                            className={styles.input}
                            value={cardDetails.number}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        <div>
                            <label>Validade (MM/AA)</label>
                            <input
                                type="text"
                                name="expiry"
                                placeholder="MM/AA"
                                className={styles.input}
                                value={cardDetails.expiry}
                                onChange={(e) => handleInputChange(e, 1)}
                                onFocus={handleFocusChange}
                            />
                        </div>
                        <div>
                            <label>CVV</label>
                            <input
                                type="text"
                                name="cvc"
                                placeholder="CVV"
                                className={styles.input}
                                value={cardDetails.cvc}
                                onChange={(e) => handleInputChange(e, 1)}
                                onFocus={handleFocusChange}
                            />
                        </div>

                        {/* <div className={styles.cardExpiration}>
                            <div>
                                <label>Validade (MM/AA)</label>
                                <input
                                    type="text"
                                    name="expiry"
                                    placeholder="MM/AA"
                                    className={styles.input}
                                    value={cardDetails.expiry}
                                    onChange={(e) => handleInputChange(e, 1)}
                                    onFocus={handleFocusChange}
                                />
                            </div>
                            <div>
                                <label>CVV</label>
                                <input
                                    type="text"
                                    name="cvc"
                                    placeholder="CVV"
                                    className={styles.input}
                                    value={cardDetails.cvc}
                                    onChange={(e) => handleInputChange(e, 1)}
                                    onFocus={handleFocusChange}
                                />
                            </div>
                        </div> */}

                        <label>Nome Impresso no Cartão</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome no Cartão"
                            className={styles.input}
                            value={cardDetails.name}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />
                    </div>
                );
            case 'twoCards':
                return (
                    <div className={styles.cardDetails}>
                        <h3>Pagamento com Dois Cartões</h3>
                        {/* Primeira parte do pagamento (Primeiro Cartão) */}
                        <Cards
                            cvc={cardDetails.cvc}
                            expiry={cardDetails.expiry}
                            focused={focused}
                            name={cardDetails.name}
                            number={cardDetails.number}
                        />
                        <label>Número de Parcelas</label>
                        <select className={styles.input}>
                            <option>12x de R$ 25,95</option>
                        </select>
                        <label>Número do Primeiro Cartão</label>
                        <input
                            type="text"
                            name="number"
                            placeholder="Número do Primeiro Cartão"
                            className={styles.input}
                            value={cardDetails.number}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />


                        <label>Validade (MM/AA)</label>
                        <input
                            type="text"
                            name="expiry"
                            placeholder="MM/AA"
                            className={styles.input}
                            value={cardDetails.expiry}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        <label>CVV</label>
                        <input
                            type="text"
                            name="cvc"
                            placeholder="CVV"
                            className={styles.input}
                            value={cardDetails.cvc}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        {/* <div className={styles.cardExpiration}>
                            <div>
                                <label>Validade (MM/AA)</label>
                                <input
                                    type="text"
                                    name="expiry"
                                    placeholder="MM/AA"
                                    className={styles.input}
                                    value={cardDetails.expiry}
                                    onChange={(e) => handleInputChange(e, 1)}
                                    onFocus={handleFocusChange}
                                />
                            </div>
                            <div>
                                <label>CVV</label>
                                <input
                                    type="text"
                                    name="cvc"
                                    placeholder="CVV"
                                    className={styles.input}
                                    value={cardDetails.cvc}
                                    onChange={(e) => handleInputChange(e, 1)}
                                    onFocus={handleFocusChange}
                                />
                            </div>
                        </div> */}

                        <label>Nome Impresso no Primeiro Cartão</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome no Primeiro Cartão"
                            className={styles.input}
                            value={cardDetails.name}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        {/* Segunda parte do pagamento (Segundo Cartão) */}
                        <h3>Detalhes do Segundo Cartão</h3>
                        <Cards
                            cvc={cardDetails2.cvc}
                            expiry={cardDetails2.expiry}
                            focused={focused}
                            name={cardDetails2.name}
                            number={cardDetails2.number}
                        />
                        <label>Número do Segundo Cartão</label>
                        <input
                            type="text"
                            name="number"
                            placeholder="Número do Segundo Cartão"
                            className={styles.input}
                            value={cardDetails2.number}
                            onChange={(e) => handleInputChange(e, 2)}
                            onFocus={handleFocusChange}
                        />

                        <div>
                            <label>Validade (MM/AA)</label>
                            <input
                                type="text"
                                name="expiry"
                                placeholder="MM/AA"
                                className={styles.input}
                                value={cardDetails2.expiry}
                                onChange={(e) => handleInputChange(e, 2)}
                                onFocus={handleFocusChange}
                            />
                        </div>
                        <div>
                            <label>CVV</label>
                            <input
                                type="text"
                                name="cvc"
                                placeholder="CVV"
                                className={styles.input}
                                value={cardDetails2.cvc}
                                onChange={(e) => handleInputChange(e, 2)}
                                onFocus={handleFocusChange}
                            />
                        </div>

                        {/* <div className={styles.cardExpiration}>
                            <div>
                                <label>Validade (MM/AA)</label>
                                <input
                                    type="text"
                                    name="expiry"
                                    placeholder="MM/AA"
                                    className={styles.input}
                                    value={cardDetails2.expiry}
                                    onChange={(e) => handleInputChange(e, 2)}
                                    onFocus={handleFocusChange}
                                />
                            </div>
                            <div>
                                <label>CVV</label>
                                <input
                                    type="text"
                                    name="cvc"
                                    placeholder="CVV"
                                    className={styles.input}
                                    value={cardDetails2.cvc}
                                    onChange={(e) => handleInputChange(e, 2)}
                                    onFocus={handleFocusChange}
                                />
                            </div>
                        </div> */}

                        <label>Nome Impresso no Segundo Cartão</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome no Segundo Cartão"
                            className={styles.input}
                            value={cardDetails2.name}
                            onChange={(e) => handleInputChange(e, 2)}
                            onFocus={handleFocusChange}
                        />
                    </div>
                );
            case 'pix':
                return (
                    <div className={styles.pixDetails}>
                        <h3>Pagamento via PIX</h3>
                        <p>Use o QR Code gerado para efetuar o pagamento.</p>
                        <button className={styles.generatePixButton}>Gerar QR Code</button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.checkoutContainer}>
            {loading && <p>Carregando...</p>}
            {!loading && error && <p style={{ color: 'red' }}>{error}</p>}

            {!loading && data && (
                <div className={styles.formContainer}>
                    <h2>Formas de Pagamento</h2>
                    <div className={styles.tabs}>
                        <button
                            className={paymentOption === 'oneCard' ? styles.activeTab : ''}
                            onClick={() => setPaymentOption('oneCard')}
                        >
                            Pagar com 1 Cartão
                        </button>
                        <button
                            className={paymentOption === 'twoCards' ? styles.activeTab : ''}
                            onClick={() => setPaymentOption('twoCards')}
                        >
                            Pagar com 2 Cartões
                        </button>
                        <button
                            className={paymentOption === 'pix' ? styles.activeTab : ''}
                            onClick={() => setPaymentOption('pix')}
                        >
                            Pix
                        </button>
                    </div>

                    <div className={styles.paymentContent}>{renderPaymentFields()}</div>

                    <div className={styles.addressContainer}>
                        <h3>Endereço da Fatura do Cartão</h3>
                        <div className={styles.addressFields}>
                            <input type="text" placeholder="CEP" className={styles.input} />
                            <input type="text" placeholder="Bairro" className={styles.input} />
                            <input type="text" placeholder="Cidade" className={styles.input} />
                            <input type="text" placeholder="Estado" className={styles.input} />
                        </div>
                    </div>

                    <div className={styles.paymentButtonContainer}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className={styles.checkbox}
                        />
                        <label>Eu concordo com os termos e condições</label>
                        <button className={styles.paymentButton} disabled={!isChecked}>
                            Continuar com o pagamento
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckoutFinish;
