import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ModalComponent from './ModalCheckout';
import styles from './SimulatePayment.module.css';
import Sidebar from './Sidebar';

const CheckoutPage = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [valor, setValor] = useState('');
  const [tipoConta, setTipoConta] = useState(''); // 'boleto' ou 'pix'
  const [codigo, setCodigo] = useState(''); // Código de barras ou PIX
  const history = useHistory();
  const location = useLocation();

  // Função para abrir o modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Processar as informações recebidas via props ou state
  useEffect(() => {
    // Verificar se as informações foram passadas via state (react-router)
    const checkoutData = location.state || props;
    
    if (checkoutData && checkoutData.valor && checkoutData.tipoConta && checkoutData.codigo) {
      setValor(checkoutData.valor);
      setTipoConta(checkoutData.tipoConta);
      setCodigo(checkoutData.codigo);

      // Abrir o modal automaticamente para processar o checkout
      openModal();
    } else {
      alert('Informações de checkout inválidas. Verifique os dados.');
      // history.push('/checkout-error'); // Redireciona em caso de erro
    }
  }, [location.state, props, history]);

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h2>Facilite o pagamento da sua conta com a CarinaPaga!</h2>
                <p>
                  Com a CarinaPaga, você pode simular diferentes opções de pagamento para suas faturas e despesas. Nossa plataforma oferece ferramentas intuitivas para que você possa visualizar e comparar alternativas de parcelamento e quitação.
                </p>
                <br />
                
                <div className={styles.simulationCard}>
                  <h2>Processando o pagamento...</h2>
                  <p>Tipo de Conta: {tipoConta === 'boleto' ? 'Boleto' : 'PIX'}</p>
                  <p>Valor: R$ {valor}</p>
                  <p>Código: {codigo}</p>
                  <p>Por favor, aguarde enquanto processamos seu pagamento.</p>
                </div>

                {/* Modal que abre automaticamente para confirmação do pagamento */}
                <ModalComponent 
                  isOpen={modalIsOpen} 
                  closeModal={closeModal} 
                  valor={valor} 
                  tipoConta={tipoConta} 
                  codigo={codigo} 
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
