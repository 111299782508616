import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './LoginDashboard.module.css';
import { toast, ToastContainer } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const FormFaturas = () => {
  const [cpfcnpj, setCpfCnpj] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const formatCpfCnpj = (value) => {
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    if (value.length <= 11) {
      // Formato de CPF: 999.999.999-99
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      // Formato de CNPJ: 99.999.999/9999-99
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  };

  const handleCpfCnpjChange = (e) => {
    const formattedValue = formatCpfCnpj(e.target.value);
    setCpfCnpj(formattedValue);
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('https://carinapaga.com.br/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cpfcnpj, password }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Login bem-sucedido! Redirecionando...');
        localStorage.setItem('token', data.token); // Armazena o token
        localStorage.setItem('cpfcnpj', cpfcnpj); // Armazena o CPF/CNPJ

        setTimeout(() => {
          history.push('/app'); // Redireciona para o dashboard
        }, 2000);
      } else {
        toast.error(`Erro: ${data.error}`);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      toast.error('Erro ao tentar logar. Tente novamente.');
    }
  };

  return (
    <div className="col-md-6 xs-hide">
      <div className="form-container">
        <div className={styles.form}>
          <div className={styles.formgroup}>
            <p className={styles.formtitle}>Área do Cliente</p>
            <span>Faça login na sua conta para começar a usar a CarinaPaga.</span>
            <div className={styles.inputgroup}>
              <input
                name="cpfcnpj"
                placeholder="CPF/CNPJ"
                id="cpfcnpj"
                className={styles.input}
                value={cpfcnpj}
                onChange={handleCpfCnpjChange}
                required
              />
              <div className={styles.passwordContainer}>
                <input
                  name="password"
                  placeholder="Senha"
                  id="password"
                  className={styles.input}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className={styles.eyeIcon}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <button
              type="button"
              className={styles.button}
              onClick={handleLogin}
              style={{ paddingRight: '8rem', paddingLeft: '8rem' }}
            >
              ACESSAR
            </button>
          </div>
          <p className={styles.agreementtext}>
            Se não tem conta, <a href="/register" className={styles.registerLink}>registre-se aqui</a>.
          </p>
          <p className={styles.agreementtext}>
            Esqueci a <a href="/register" className={styles.registerLink}>minha senha</a>.
          </p>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default FormFaturas;
