import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import styles from './ModalSimulateComponent.module.css'; // Supondo que você tenha um arquivo CSS

// Para acessibilidade, é necessário associar o modal ao root da aplicação
Modal.setAppElement('#root');

const ModalComponent = ({ isOpen, closeModal, billType }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [fetchTriggered, setFetchTriggered] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [installments, setInstallments] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null); // Limpar erro anterior
    try {
      const response = await fetch('https://carinapaga.com.br/api/app/consultation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ billType }),
      });
      const result = await response.json();
      if (response.ok) {
        setData(result);
      } else {
        setError(result.message || 'Erro desconhecido');
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  }, [billType]);

  useEffect(() => {
    if (isOpen && !fetchTriggered) {
      setFetchTriggered(true);
    }
  }, [isOpen, fetchTriggered]);

  useEffect(() => {
    if (fetchTriggered) {
      fetchData();
    }
  }, [fetchTriggered, fetchData]);

  // Lógica de cálculo com base na escolha do método de pagamento
// Função para calcular o total com base no valor, método de pagamento e número de parcelas
const calculateTotal = (value, paymentMethod, installments) => {
  let total = 0;

  // Convertendo o valor para número, se não for numérico
  const numericValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);

  // console.log("Método de Pagamento:", paymentMethod);
  // console.log("Valor recebido e selecionado:", value);
  // console.log("Parcelas selecionadas:", installments);

  // Condições para cada método de pagamento
  if (paymentMethod === 'boleto') {
    total = numericValue + 2.99; // Taxa do boleto
  } else if (paymentMethod === 'credito') {
    let percentage = 0;
    if (installments === 1) {
      percentage = 0.0399; // 3,99% para à vista
    } else if (installments === 2) {
      percentage = 0.0449 * 2; // 4,49% para 2 parcelas
    } else if (installments === 3) {
      percentage = 0.0449 * 3; // 4,49% para 3 parcelas
    } else if (installments === 4) {
      percentage = 0.0449 * 4; // 4,49% para 4 parcelas
    } else if (installments === 5) {
      percentage = 0.0449 * 5; // 4,49% para 5 parcelas
    } else if (installments === 6) {
      percentage = 0.0449 * 6; // 4,49% para 6 parcelas
    } else if (installments === 7) {
      percentage = 0.0499 * 7; // 4,99% para 7 parcelas
    } else if (installments === 8) {
      percentage = 0.0499 * 8; // 4,99% para 8 parcelas
    } else if (installments === 9) {
      percentage = 0.0499 * 9; // 4,99% para 9 parcelas
    } else if (installments === 10) {
      percentage = 0.0499 * 10; // 4,99% para 10 parcelas
    } else if (installments === 11) {
      percentage = 0.0499 * 11; // 4,99% para 11 parcelas
    } else if (installments === 12) {
      percentage = 0.0499 * 12; // 4,99% para 12 parcelas
    } 
    total = numericValue * (1 + percentage) + 14.49; // Aplica a porcentagem + taxa fixa
  } else if (paymentMethod === 'debito') {
    total = numericValue + numericValue * 0.0289 + 14.49; // 2,89% + R$14,49 para cartão de débito
  } else if (paymentMethod === 'pix') {
    total = numericValue + 0.099 + 5.00; // Taxa fixa de R$5,00 para PIX
  } else {
    console.error(`Método de pagamento não reconhecido: ${paymentMethod}`);
    return;
  }

  // Atualizando o estado totalAmount com o valor calculado
  // console.log("Total calculado:", total);
  setTotalAmount(total); // Atualizando o estado
};

// Função para lidar com a mudança do método de pagamento
const handlePaymentMethodChange = (e) => {
  const selectedMethod = e.target.value;
  setPaymentMethod(selectedMethod);

  if (data && data.data && data.data.simulationResult) {
    let valueToCalculate = 0;

    // Ajuste para buscar o valor correto baseado no método de pagamento
    if (data.data.simulationResult.bankSlipInfo) {
      valueToCalculate = data.data.simulationResult.bankSlipInfo.value || 0;
    }

    // Chama a função para calcular o total com base no valor e parcelas atuais
    calculateTotal(valueToCalculate, selectedMethod, installments);
  }
};

// Função para lidar com a mudança do número de parcelas
const handleInstallmentsChange = (e) => {
  const selectedInstallments = Number(e.target.value);
  setInstallments(selectedInstallments);

  if (data && data.data && data.data.simulationResult) {
    let valueToCalculate = 0;

    // Ajuste para buscar o valor correto baseado no método de pagamento
    if (data.data.simulationResult.bankSlipInfo) {
      valueToCalculate = data.data.simulationResult.bankSlipInfo.value || 0;
    }

    // Chama a função para calcular o total com base no valor e parcelas atualizadas
    calculateTotal(valueToCalculate, paymentMethod, selectedInstallments);
  }
};

  

  const handleRedirect = () => {
    window.location.href = '/login'; // Substitua com o caminho correto da sua página de login
  };

  const handleRegister = () => {
    window.location.href = '/register'; // Substitua com o caminho correto da sua página de registro
  };

  // Função para formatar a data no formato DD/MM/YYYY
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  // Função para formatar os dados em HTML
  const formatData = (data) => {
    if (!data || !data.success) return '';

    const { simulationResult } = data.data || {};
    const { bankSlipInfo } = simulationResult || {};
    const { identificationField, value, dueDate, isOverdue } = bankSlipInfo || {};

    return `
      <p><strong>Valor:</strong> R$ ${value.toFixed(2)}</p>
      <p><strong>Data de Vencimento:</strong> ${formatDate(dueDate)}</p>
      <p><strong>Identificação do Boleto:</strong> ${identificationField}</p>
      <p><strong>Vencido:</strong> ${isOverdue ? 'Sim' : 'Não'}</p>
    `;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Resultado da Consulta"
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      <h2>Checkout Realizado</h2>
      {loading ? (
        <p>Carregando os resultados...</p>
      ) : error ? (
        <div>
          <p>{error}</p>
          <div className={styles.redirectButtonContainer}>
            <button onClick={handleRedirect} className={styles.redirectButton}>
              Ir para a página de login
            </button>
          </div>
        </div>
      ) : data ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: formatData(data) }} />

          <div className={styles.paymentMethodContainer}>
          <select onChange={handlePaymentMethodChange} className={styles.input}>
            <option value="">Selecione o método de pagamento</option>
            <option value="boleto">Boleto</option>
            <option value="credito">Crédito</option>
            <option value="debito">Débito</option>
            <option value="pix">PIX</option>
          </select>
          </div>

          {paymentMethod === 'credito' && (
            <div className={styles.installmentsContainer}>
              <label style={{marginLeft: '9rem'}}>
                <strong>Quantidade de Parcelas:</strong>
                <select value={installments} onChange={handleInstallmentsChange} className={styles.input} style={{marginLeft: '-0.1rem'}}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                    <option key={i} value={i}>
                      {i}x
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}

          <div className={styles.totalAmountContainer}>
            <h3 style={{marginTop: '20px', marginLeft: '9rem'}}>Total a pagar:</h3>
            {loading ? (
              <p>Calculando...</p> // Exibe mensagem de carregamento
            ) : (
              <p>R$ {totalAmount.toFixed(2)}</p>
            )}
          </div>

          <div className={styles.actionButtons}>
            <button onClick={handleRegister} className={styles.registerButton}>
              Realizar Pagamento
            </button>
          </div>
        </div>
      ) : (
        <p>Nenhum dado disponível.</p>
      )}
    </Modal>
  );
};

export default ModalComponent;
