import React, { useCallback, useState, useEffect } from 'react';
import styles from './FormatBarcode.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importa o CSS do react-toastify
import CheckoutFinish from './CheckoutFinish';

const FormatBarcode = ({ code }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [showPayment, setShowPayment] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);  // Para controlar o carregamento do spinner
    const [showCheckoutFinish, setShowCheckoutFinish] = useState(false); // Novo estado para controlar o CheckoutFinish

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://carinapaga.com.br/api/app/consultation', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ billType: code }),
            });

            const result = await response.json();

            if (response.ok) {
                setData(result.data.simulationResult.bankSlipInfo);
            } else {
                const errorMessage = result.errors?.[0]?.description || result.message || 'Erro desconhecido';
                setError(errorMessage);
            }
        } catch (error) {
            setError('Erro ao conectar com o servidor.');
        } finally {
            setLoading(false);
        }
    }, [code]);

    useEffect(() => {
        if (code) {
            fetchData();
        }
    }, [code, fetchData]);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handlePaymentContinue = () => {
        if (!isChecked) {
            toast.error('Por favor, selecione o checkbox para continuar com o pagamento.'); // Exibir erro com react-toastify
        } else {
            setShowPayment(false); // Ocultar a seção de pagamento
            setIsProcessing(true); // Ativar o spinner de carregamento

            // Simular o processo de transição (substitua isso com sua chamada ao próximo componente ou lógica)
            setTimeout(() => {
                setIsProcessing(false); // Desativa o spinner após o processo
                setShowCheckoutFinish(true); // Exibe o componente CheckoutFinish
            }, 2000);  // Simulando 2 segundos de espera (ajuste conforme necessário)
        }
    };

    return (
        <div className={styles.barcodeContainer}>
            {loading && <p>Carregando...</p>}
            {!loading && error && <p style={{ color: 'red' }}>{error}</p>}

            {isProcessing ? (
                // Spinner de loading
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div> {/* Loading Spinner */}
                    <p>
                        Estamos processando seu código de barras e redirecionando para você escolher a forma de pagamento. Por favor, aguarde...
                    </p>
                </div>
            ) : (
                showPayment && !loading && data && (
                    <div>
                        {/* Tabela para dispositivos maiores */}
                        <div className={`${styles.desktopView}`}>
                            <table className={styles.paymentTable}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Descrição</th>
                                        <th>Valor</th>
                                        <th>Vencimento</th>
                                        <th>Beneficiário</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className={styles.checkbox}
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                        </td>
                                        <td>BOLETO</td>
                                        <td>R$ {data ? data.value.toFixed(2) : '0,00'}</td>
                                        <td>{data ? formatDate(data.dueDate) : 'N/A'}</td>
                                        <td>{data ? data.beneficiaryName : 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={styles.paymentButtonContainer}>
                                <button
                                    className={styles.paymentButton}
                                    disabled={!isChecked}
                                    onClick={handlePaymentContinue}
                                >
                                    Continuar com o pagamento
                                </button>
                            </div>
                        </div>

                        {/* Exibição para dispositivos móveis */}
                        <div className={styles.mobileView}>
                            <div className={styles.mobileDetails}>
                                <p><strong>Descrição:</strong> BOLETO</p>
                                <p><strong>Valor:</strong> R$ {data ? data.value.toFixed(2) : '0,00'}</p>
                                <p><strong>Vencimento:</strong> {data ? formatDate(data.dueDate) : 'N/A'}</p>
                                <p><strong>Beneficiário:</strong> {data ? data.beneficiaryName : 'N/A'}</p>
                                <div>
                                    <input
                                        type="checkbox"
                                        className={styles.checkbox}
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label>Marcar para continuar</label>
                                </div>
                            </div>
                            <button
                                className={styles.paymentButton}
                                disabled={!isChecked}
                                onClick={handlePaymentContinue}
                            >
                                Continuar com o pagamento
                            </button>
                        </div>
                    </div>
                )
            )}

            {/* Renderiza o CheckoutFinish após o processo */}
            {showCheckoutFinish && <CheckoutFinish code={code}/>}
            
            <ToastContainer /> {/* Componente do toast para exibir as notificações */}
        </div>
    );
};

export default FormatBarcode;
