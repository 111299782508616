// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__oppbQ {
    width: 100%; /* Garante que o container ocupe toda a largura da tela */
    max-width: 1100px; /* Limita o tamanho máximo do container para 1100px */
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 0 20px; /* Adiciona um pequeno padding para dar espaçamento nas laterais */
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
    .Container_container__oppbQ {
        width: 100%; /* No mobile, o container vai ocupar 100% da largura */
        padding: 0; /* Remove o padding lateral */
        margin: 0; /* Remove a margem lateral */
    }
}

.Container_min-height__dZre0 {
    min-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/layout/Container.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,yDAAyD;IACtE,iBAAiB,EAAE,qDAAqD;IACxE,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,eAAe;IACf,eAAe,EAAE,kEAAkE;AACvF;;AAEA,4CAA4C;AAC5C;IACI;QACI,WAAW,EAAE,sDAAsD;QACnE,UAAU,EAAE,6BAA6B;QACzC,SAAS,EAAE,4BAA4B;IAC3C;AACJ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container {\r\n    width: 100%; /* Garante que o container ocupe toda a largura da tela */\r\n    max-width: 1100px; /* Limita o tamanho máximo do container para 1100px */\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 0 auto;\r\n    flex-wrap: wrap;\r\n    padding: 0 20px; /* Adiciona um pequeno padding para dar espaçamento nas laterais */\r\n}\r\n\r\n/* Responsividade para dispositivos móveis */\r\n@media (max-width: 768px) {\r\n    .container {\r\n        width: 100%; /* No mobile, o container vai ocupar 100% da largura */\r\n        padding: 0; /* Remove o padding lateral */\r\n        margin: 0; /* Remove a margem lateral */\r\n    }\r\n}\r\n\r\n.min-height {\r\n    min-height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__oppbQ`,
	"min-height": `Container_min-height__dZre0`
};
export default ___CSS_LOADER_EXPORT___;
