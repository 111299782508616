import React, { useRef, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styles from './RegisterDashboard.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

const FormFaturas = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false); // Estado para alternar visibilidade da senha
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para senha de confirmação
  const [cpfCnpjValue, setCpfCnpjValue] = useState("");

  const formatCpfCnpj = (value) => {
    value = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

    if (value.length <= 11) {
      // Formato de CPF: 999.999.999-99
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      // Formato de CNPJ: 99.999.999/9999-99
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
  };

  const handleCpfCnpjChange = (event) => {
    const formattedValue = formatCpfCnpj(event.target.value);
    setCpfCnpjValue(formattedValue);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    try {
      const response = await fetch('https://carinapaga.com.br/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        if (formRef.current) {
          formRef.current.reset();
        }
        toast.success('Você foi registrado com sucesso em alguns segundos, você será redirecionado!');
        setTimeout(() => {
          history.push('/login');
        }, 5000);
      } else {
        const errorData = await response.json();
        toast.error(`${errorData.error}`);
      }
    } catch (error) {
      toast.error('Erro ao enviar os dados.');
    }
  };

  return (
    <div className="col-md-6 xs-hide">
      <div className={styles['form-container']}>
        <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
          <div className={styles.formgroup}>
            <span>Preencha os campos abaixo para criar sua conta.</span>

            <div className={styles.inputgroup}>
              <div className={styles['input-container']}>
                <input name="firstName" placeholder="Nome" id="firstName" className={styles.input} type="text" required />
              </div>
              <div className={styles['input-container']}>
                <input name="lastName" placeholder="Sobrenome" id="lastName" className={styles.input} type="text" required />
              </div>
              <div className={styles['input-container']}>
                <InputMask
                  mask="99/99/9999"
                  name="dob"
                  placeholder="Data de Nascimento"
                  id="dob"
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles['input-container']}>
                <InputMask
                  mask="(99) 99999-9999"
                  name="phone"
                  placeholder="Telefone com DDD"
                  id="phone"
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles['input-container']}>
              <input
                value={cpfCnpjValue}
                onChange={handleCpfCnpjChange}
                name="cpfcnpj"
                placeholder="CPF/CNPJ"
                id="cpfcnpj"
                className={styles.input}
                required
              />
            </div>
              <div className={styles['input-container']}>
                <input name="email" placeholder="Email" id="email" className={styles.input} type="email" required />
              </div>
              
              {/* Campo de Senha com ícone de olho */}
              <div className={styles['input-container']}>
              <input
                name="password"
                placeholder="Senha"
                id="password"
                className={styles.input}
                type={showPassword ? 'text' : 'password'}
                required
              />
              <span
                className={styles.eyeIcon}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className={styles['input-container']}>
              <input
                name="confirmPassword"
                placeholder="Confirmar Senha"
                id="confirmPassword"
                className={styles.input}
                type={showConfirmPassword ? 'text' : 'password'}
                required
              />
              <span
                className={styles.eyeIcon}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            </div>

            <div className={styles.terms}>
              <input type="checkbox" id="terms" name="terms" required style={{ marginRight: '0.5rem' }} />
              <label htmlFor="terms">
                Aceito os <a href="/termos.pdf" className={styles.registerLink}>termos e condições</a>
              </label>
            </div>

            <button type="submit" className={styles.button} data-cy="register-button" style={{ paddingRight: '8rem', paddingLeft: '8rem' }}>
              REGISTRAR
            </button>
          </div>

          <p className={styles.agreementtext}>
            Já tem uma conta? <a href="/login" className={styles.registerLink}>Faça login aqui</a>.
          </p>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default FormFaturas;
