import React from 'react';
import Button from '@mui/material/Button';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import './contact.css';

const Contact = () => {
  return (
    <div className="contato">
       <div className="button-container">
       <a href="https://carinabank.com.br" target="_blank" rel="noopener noreferrer">
        <Button style={{background: "rgb(255, 255, 255)", color: "#000", border: "1px solid #e63888", borderRadius: '5px', fontSize: '0.8rem', padding: '0.5rem 1rem', height: 'auto', letterSpacing: '1px'}}>
          CarinaBank <ArrowCircleRightIcon />
        </Button>
      </a>
      </div>
      <div className="info">
        <span className="email">contato@carinabank.com.br</span>
        <span className="telefone">
          Fale conosco: <a className="link-telefone" href="tel:11996519092"><strong>(11) 97864-1820</strong></a>
        </span>
      </div>
    </div>
  );
}

export default Contact;
