import React from 'react';
import styles from './AppDash.module.css';
import SimulatePayment from '../components/SimulatePayment';

function SimulatePayment2() {
    // Estado para controlar o texto ativo
    // const [activeText] = useState(1); // Se for questão veicular altere aqui para 2, caso seja só pagamento de faturas altere para 1;

    return (
        <div className={styles.dashboardContainer}>
            <div className="row align-items-center">
                <SimulatePayment />
            </div>

            <div className="spacer-10"></div>
        </div>
    );
}

export default SimulatePayment2;
