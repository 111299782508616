import React from 'react';
import styles from './Footer.module.css';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';
// eslint-disable-next-line
function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.logo}>
            <h1>CarinaPaga</h1>
            <p className={styles.footer23}>A solução chegou!</p>
        </div>
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.title}>Soluções</div>
            <div className={styles.dropdown}>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Consultar Débitos Veículares</a>
              <a href="https://carinapaga.com.br/login" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Consultar Contas de Enérgia</a>
              <a href="https://carinapaga.com.br/login" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Consultar Contas de Água</a>
              <a href="https://carinapaga.com.br/login" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Consultar Score</a>
              <a href="https://carinapaga.com.br/login" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Negociamento de Dívidas</a>
              <a href="https://carinapaga.com.br/login" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Acompanhar Pedidos</a>
              <a href="https://carinapaga.com.br/login" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Verificar Resultados</a>
              <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Falar com a CarinaPaga</a>
              <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Atualizar meus dados</a>
              <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Soluções para empresas</a>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.title}>Produtos</div>
            <div className={styles.dropdown}>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de Multas</a>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de IPVA</a>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de Licenciamento</a>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de ITBI/ITCD</a>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de IPTU</a>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de Guias de Arrecadação</a>
              <a href="https://debitosveiculares.celcoin.com.br/webview/carinabank" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pagamento de Boleto Diversos</a>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.title}>Ajuda e atendimento</div>
            <div className={styles.dropdown}>
              <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Pontos de Atendimento</a>
              <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Telefones</a>
              <a href="https://wa.me/11978641820" target="_blank" rel="noopener noreferrer" className={styles.buttonLink}>Central de Ajuda</a>
            </div>
          </div>
        </div>
        <div className={styles.socialIcons}>
          <a href="http://carinapaga.com.br/" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="http://carinapaga.com.br/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="http://carinapaga.com.br/" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="http://carinapaga.com.br/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        </div>
        <div className={styles.address}>
          <p>© A CarinaPaga é uma marca da Personal Consultant Brasil, registrada sob o CNPJ 39.502.592/0001-85.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
